$(document).ready(function () {

    if (jQuery().slick) {
        //si video
        /*
	    $('.slider').on('init', function (event, slick) {
            $('.slick-next').show();
            $('.slick-prev').hide();

            $('.slick-current').find('.visual_container iframe').each(function (index) {
                if($(this).attr('class') == 'embed-responsive-item autoplay'){
                    $(this).attr('src', $(this).attr('src') + '&autoplay=1');
                }
            });
        });

        $('.slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {

            $('.slick-current').find('.visual_container iframe').each(function (index) {
                callPlayer("video_container", "pauseVideo");
            });
        });

        $('.slider').on('afterChange', function (event, slick, currentSlide) {
            $('.slick-current').find('.visual_container iframe').each(function (index) {
                if($(this).attr('class') == 'embed-responsive-item autoplay') {
                    callPlayer('video_container', 'playVideo');
                }
            });
        });*/
        $(".slider").slick({
            dots: false, arrows: true, autoplay: true, pauseOnHover: false, speed: 2000, fade: true,
            prevArrow: '<button class="slick-prev slick-arrow"></button>',
            nextArrow: '<button class="slick-next slick-arrow"></button>'
        });

        $('.slider-marque').slick({
            dots: false,
            arrows: false,
            pauseOnHover: false,
            slidesToShow: 8,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }]

        });

        if (window.innerWidth < 575) {
            $('.picto-home > .row').slick({
                dots: true,
                arrows: false,
                pauseOnHover: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
            });
        }
    }

    function callPlayer(frame_id, func, args) {
        /*func: playVideo, pauseVideo, stopVideo, ... Full list at:
         * http://code.google.com/apis/youtube/js_api_reference.html#Overview */
        if (!document.getElementById(frame_id)) return;
        args = args || [];

        /*Searches the document for the IFRAME with id=frame_id*/
        var all_iframes = document.getElementsByTagName("iframe");
        for (var i = 0, len = all_iframes.length; i < len; i++) {
            if (all_iframes[i].parentNode.id == frame_id) {
                /*The index of the IFRAME element equals the index of the iframe in
                 the frames object (<frame> . */
                window.frames[i].postMessage(JSON.stringify({
                    "event": "command",
                    "func": func,
                    "args": args,
                    "id": 1/*Can be omitted.*/
                }), "*");
            }
        }
    }

    if (jQuery().lightcase) {
        $('.lightcase').lightcase({
            maxWidth: 2000,
            maxHeight: 2000
        });
    }

    $('.js-datepicker').datepicker({
        altField: '.js-datepicker',
        closeText: 'Fermer',
        prevText: 'Précédent',
        nextText: 'Suivant',
        currentText: 'Aujourd\'hui',
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        weekHeader: 'Sem.',
        dateFormat: 'dd/mm/yy'
    });

    var bLazy = new Blazy();


    /********** Scroll Animations ***************/
    var windowBottom = $(window).scrollTop() + ($(window).innerHeight() / 4) * 3;
    $(".animated-zone").each(function () {
        var objectBottom = $(this).offset().top;
        var effect = $(this).data('effect');

        if (objectBottom < windowBottom) {
            if (!$(this).hasClass('animated')) {
                $(this).addClass('animated');
            }
        } else {
            $(this).css('opacity', 0);
        }
    });

    $(window).scroll(function () {

        var windowBottom = $(this).scrollTop() + ($(this).innerHeight() / 4) * 3;
        $(".animated-zone").each(function () {
            var objectBottom = $(this).offset().top;
            var effect = $(this).data('effect');

            if (objectBottom < windowBottom) {
                if (!$(this).hasClass('animated')) {
                    $(this).css('opacity', 1).addClass('animated ' + effect);
                }
            }
        });

    }).scroll();

    $('#btTop').click(function () {
        var speed = 750; // Durée de l'animation (en ms)
        $('html, body').animate({scrollTop: 0}, speed); // Go
    });

    if ($(".contact_thanks")[0]){
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".contact_thanks").offset().top
        }, 2000);
    }

});