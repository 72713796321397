/*
 FlexNav.js 1.3.3

 Created by Jason Weaver http://jasonweaver.name
 Released under http://unlicense.org/
 Modifié par Fabrice DÉMARAIS:
 - Option pour définir le point de rupture entre pc et mobile (breakpoint)
 - Option pour choisir si le menu apparait au clique sur pc (click)
 - Option pour choisir si les sous menus s'affichent au survol ou si il apparaisent déjà dépliés (hideChild)
 - Option pour choisir si on affiche un niveau de sous-navigation ou tous sur pc (oneChild)

 //
 */


(function() {
    var $;

    $ = jQuery;

    $.fn.flexNav = function(options) {
        var $nav, $top_nav_items, breakpoint, count, nav_percent, nav_width, resetMenu, resizer, settings, showMenu, toggle_selector, touch_selector;
        settings = $.extend({
            'animationSpeed': 250,
            'transitionOpacity': true,
            'buttonSelector': '.menu-button',
            'hoverIntent': false,
            'hoverIntentTimeout': 150,
            'calcItemWidths': false,
            'hover': false,
            'click': false,
            'hideChild': true,
            'oneChild': true,
            'breakpoint': 992
        }, options);
        $nav = $(this);
        $nav.addClass('with-js');
        if (settings.transitionOpacity === true) {
            $nav.addClass('opacity');
        }

        $nav.find("li").each(function() {
            if ($(this).has("ul").length) {
                return $(this).addClass("item-with-ul").find("ul").hide();
            }
        });

        if (settings.calcItemWidths === true) {
            $top_nav_items = $nav.find('>li');
            count = $top_nav_items.length;
            nav_width = 100 / count;
            nav_percent = nav_width + "%";
        }
        breakpoint = settings.breakpoint;
        showMenu = function() {
            if ($nav.hasClass('lg-screen') === true && settings.hover === true) {
                if (settings.transitionOpacity === true) {
                    return $(this).find('>ul').addClass('flexnav-show').stop(true, true).animate({
                        height: ["toggle", "swing"],
                        opacity: "toggle"
                    }, settings.animationSpeed);
                } else {
                    return $(this).find('>ul').addClass('flexnav-show').stop(true, true).animate({
                        height: ["toggle", "swing"]
                    }, settings.animationSpeed);
                }
            }
        };
        resetMenu = function() {
            if ($nav.hasClass('lg-screen') === true && $(this).find('>ul').hasClass('flexnav-show') === true && settings.hover === true) {
                if (settings.transitionOpacity === true) {
                    return $(this).find('>ul').removeClass('flexnav-show').stop(true, true).animate({
                        height: ["toggle", "swing"],
                        opacity: "toggle"
                    }, settings.animationSpeed);
                } else {
                    return $(this).find('>ul').removeClass('flexnav-show').stop(true, true).animate({
                        height: ["toggle", "swing"]
                    }, settings.animationSpeed);
                }
            }
        };
        resizer = function() {
            var selector;
            if ($(window).width() <= breakpoint) {
                $nav.removeClass("lg-screen").addClass("sm-screen");
                if (settings.calcItemWidths === true) {
                    $top_nav_items.css('width', '100%');
                }
                selector = settings['buttonSelector'] + ', ' + settings['buttonSelector'] + ' .touch-button';
                $(selector).removeClass('active');
                return $('.one-page li a').on('click', function() {
                    return $nav.removeClass('flexnav-show');
                });
            } else if ($(window).width() > breakpoint) {
                $nav.removeClass("sm-screen").addClass("lg-screen");
                if (settings.calcItemWidths === true) {
                    $top_nav_items.css('width', nav_percent);
                }
                $nav.removeClass('flexnav-show').find('.item-with-ul').off();
                if (settings.hideChild) {
                    $('.item-with-ul').find('ul').removeClass('flexnav-show').hide();
                } else {
                    $('.item-with-ul').find('ul').addClass('flexnav-show').show();
                    $($nav).children("li.item-with-ul").children('ul').removeClass('flexnav-show').hide();
                }
                resetMenu();
                if (settings.hoverIntent === true) {
                    return $('.item-with-ul').hoverIntent({
                        over: showMenu,
                        out: resetMenu,
                        timeout: settings.hoverIntentTimeout
                    });
                } else if (settings.hoverIntent === false) {
                    if (!settings.oneChild && settings.hideChild) {
                        return $('.item-with-ul').on('mouseenter', showMenu).on('mouseleave', resetMenu);
                    } else {
                        return $nav.children("li.item-with-ul").on('mouseenter', showMenu).on('mouseleave', resetMenu);
                    }
                }
            }
        };
        $(settings['buttonSelector']).data('navEl', $nav);
        touch_selector = '.item-with-ul, ' + settings['buttonSelector'];
        $(touch_selector).append('<span class="touch-button"><i class="navicon">&#9660;</i></span>');
        toggle_selector = settings['buttonSelector'] + ', ' + settings['buttonSelector'] + ' .touch-button';
        $(toggle_selector).on('click', function(e) {
            var $btnParent, $thisNav, bs;
            $(toggle_selector).toggleClass('active');
            e.preventDefault();
            e.stopPropagation();
            bs = settings['buttonSelector'];
            $btnParent = $(this).is(bs) ? $(this) : $(this).parent(bs);
            $thisNav = $btnParent.data('navEl');
            return $thisNav.toggleClass('flexnav-show');
        });
        $('.touch-button').on('click', function(e) {
            var $sub, $touchButton;
            $sub = $(this).parent('.item-with-ul').find('>ul');
            $touchButton = $(this).parent('.item-with-ul').find('>span.touch-button');
            if ($nav.hasClass('lg-screen') === true) {
                $(this).parent('.item-with-ul').siblings().find('ul.flexnav-show').removeClass('flexnav-show').hide();
            }
            if ($sub.hasClass('flexnav-show') === true) {
                $sub.removeClass('flexnav-show').slideUp(settings.animationSpeed);
                return $touchButton.removeClass('active');
            } else if ($sub.hasClass('flexnav-show') === false) {
                $sub.addClass('flexnav-show').slideDown(settings.animationSpeed);
                return $touchButton.addClass('active');
            }
        });
        if (settings.click === true) {
            $nav.find('.item-with-ul *').focus(function() {
                $(this).parent('.item-with-ul').parent().find(".open").not(this).removeClass("open").hide();
                return $(this).parent('.item-with-ul').find('>ul').addClass("open").show();
            });
        }
        resizer();
        return $(window).on('resize', resizer);
    };

}).call(this);
